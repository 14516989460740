import styled from 'styled-components';

const Title = styled.h1`
  font-family: 'Cormorant Garamond', serif;
  font-weight: 300;
  font-size: 26px;
  line-height: 1.2em;
  margin: 0 0 1em;
  @media only screen and (min-width: 768px) {
    font-size: 30px;
  }
`;

export default Title;
