import React, {Component} from 'react';
import {Link} from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';
import Section from '../components/Section';
import Layout, {tt} from '../components/layout';
import Title from '../components/Title';

export class Item extends Component {
  state = {ready: false, visible: false};

  cover = () => this.props.cover && this.props.cover.resolutions;

  handleLoad = () => {
    this.setState({ready: true});
  };

  handleVisible = visible => {
    if (!this.state.visible && visible) {
      this.setState({visible: true});
    }
  };
  render() {
    const {post} = this.props;
    const data = this.cover();

    return (
      <StyledLink to={post.fields.slug}>
        <StyledCover>
          {data && (
            <Covers width={data.width} height={data.height}>
              <Imgs>
                <VisibilitySensor partialVisibility onChange={this.handleVisible}>
                  <span>
                    <Img
                      visible
                      width={data.width}
                      height={data.height}
                      src={data.base64}
                    />

                    {this.state.visible && (
                      <Img
                        visible={this.state.ready}
                        src={data.src}
                        srcSet={data.srcSet}
                        width={data.width}
                        height={data.height}
                        onLoad={this.handleLoad}
                      />
                    )}
                  </span>
                </VisibilitySensor>
              </Imgs>
              <StyledCaption width={data.width} height={data.height}>
                {post.frontmatter.title}
              </StyledCaption>
            </Covers>
          )}
        </StyledCover>
      </StyledLink>
    );
  }
}
const Imgs = styled.span`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

const StyledCaption = styled.span`
  display: block;
  margin-top: 12px;
  text-align: right;
  position: absolute;
  top: 100%;
  right: 0;
  font-weight: 300;
`;

const StyledCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translate3d(0, 0, 0);
`;

const Head = styled.header``;

const Tags = styled.nav`
  line-height: 1em;
  font-size: 0.75em;
  margin: -12px -12px 0;

  @media only screen and (min-width: 600px) {
    font-size: 0.8em;
  }
`;

const Tag = styled(Link)`
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 0.2em;
  display: inline-block;
  padding: 6px 12px;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  ${({active}) => active && `font-weight: bold; opacity: 1; cursor: default;`}
`;
const Img = props => {
  return <StyledImg {...props} />;
};

const StyledImg = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: all 0.1s ease-in-out;
  margin: 0;
  transform: translate3d(0, 0, 0);

  &:first-child {
    transform: scale(1.15);
    filter: blur(8px);
    position: relative;
  }

  ${({visible}) => visible && `opacity: 1`};
`;

const Covers = styled.span`
  display: block;
  position: relative;

  ${p => {
    const ratio = p.width / p.height;
    if (ratio < 1) {
      return `
        width: ${(100 * ratio).toFixed(2)}%;
        padding-top: 100%
      `;
    } else {
      return `
        width: 100%;
        height: auto;
        padding-top: ${(100 / ratio).toFixed(2)}%
      `;
    }
  }};
`;

const StyledLink = styled(Link)`
  width: 100%;
  max-width: 400px;
  display: block;
  padding: 20px;
  position: relative;
  padding: 20px 20px 52px;

  > div {
    transition: all 0.5s ease-in-out;
  }
  &:hover {
    > div {
      opacity: 0.57;
    }
  }

  &:hover {
    color: #000;
  }

  @media only screen and (min-width: 600px) {
    width: 50%;
  }

  @media only screen and (min-width: 960px) {
    width: 33%;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;
`;

export default class IndexPage extends React.Component {
  render() {
    const {posts, covers, tags} = this.props.pageContext;
    const {pathname} = this.props.location;
    // console.log(this.props.pageContext);
    let title = <span>Природа человека</span>;

    return (
      <Layout location={this.props.location}>
        <Section>
          <Head>
            <Title>{title}</Title>

            <Tags>
              {tags.map(t => (
                <Tag
                  active={pathname === `/${t}/` ? 1 : 0}
                  key={t}
                  to={`/${t}/`}
                >
                  {tt[t] || t}
                </Tag>
              ))}
            </Tags>
          </Head>
        </Section>

        <Grid>
          {posts.map(({node: post}) => {
            return (
              <Item
                key={post.id}
                cover={covers[post.fields.slug]}
                post={post}
              />
            );
          })}
        </Grid>
      </Layout>
    );
  }
}
